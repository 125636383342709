import React from 'react';
import {
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Box,
  useTab,
  chakra,
  useStyles,
} from '@chakra-ui/react';

import { BankTransferIcon } from '../../../assets/iconsComponents/bankTransferIcon';
import { PayPalIcon } from '../../../assets/iconsComponents/payPalIcon';
import BankTransferInputs from './PaymentBankTransferInputs';
import PayPalInputs from './AddPaymentPayPalInputs';
import { hasPayPal } from '../../../util/supplier/helper';

export default function AddPaymentDetailsTab({
  onClose,
  supplier,
  updateSupplier,
  isChecked,
  isEdit,
}) {
  // 1. Reuse the styles for the Tab
  const StyledTab = chakra('button', { themeKey: 'Tabs.Tab' });
  const defaultIndex = hasPayPal(supplier) ? 1 : 0;
  const CustomTab = React.forwardRef((props, ref) => {
    // 2. Reuse the `useTab` hook
    const tabProps = useTab(props);

    const isSelected = !!tabProps['aria-selected'];
    const firstTab = tabProps.banktransfer;
    // 3. Hook into the Tabs `size`, `variant`, props
    const styles = useStyles();

    return (
      <StyledTab __css={styles.tab} {...tabProps}>
        {tabProps.children}
        {isSelected ? (
          <Box
            h="5px"
            w="26px"
            bg="#eb7f24"
            borderRadius="8px"
            ml={firstTab ? `46px` : `5px`}
            mt="-5px"
            position="absolute"
          />
        ) : (
          <Box
            h="5px"
            w="26px"
            bg="transparent"
            borderRadius="8px"
            ml="46px"
            mt="-5px"
            position="absolute"
          />
        )}
      </StyledTab>
    );
  });
  return (
    <Tabs variant="unstyled" defaultIndex={defaultIndex}>
      <TabList>
        <CustomTab
          border="1px solid #e0e1e5"
          borderRadius="8px"
          width="260px"
          color=" #a9b1b4"
          fontWeight="700"
          pt="14px"
          pb="10px"
          mr="10px"
          banktransfer
          _selected={{ color: 'white', bg: 'black' }}
          _focus={{ outline: 'none' }}
        >
          <Box d="inline-block" mr="10px">
            <BankTransferIcon fill="#fff" height="23px" width="24px" />
          </Box>
          <Box d="inline-block" verticalAlign="super">
            BankTransfer
          </Box>
        </CustomTab>
        <CustomTab
          border="1px solid #e0e1e5"
          borderRadius="8px"
          width="137px"
          color=" #a9b1b4"
          fontWeight="700"
          pt="14px"
          pb="10px"
          paypal
          _selected={{ color: 'white', bg: 'black' }}
          _focus={{ outline: 'none' }}
        >
          <Box d="inline-block" mr="10px">
            <PayPalIcon fill="#fff" height="23px" width="24px" />
          </Box>
          <Box d="inline-block" verticalAlign="super">
            PayPal
          </Box>
        </CustomTab>
      </TabList>
      <TabPanels>
        <TabPanel pt="45px" p="0">
          <BankTransferInputs
            onClose={onClose}
            supplier={supplier}
            updateSupplier={updateSupplier}
            isChecked={isChecked}
            isEdit={isEdit}
          />
        </TabPanel>
        <TabPanel pt="45px" p="0">
          <PayPalInputs
            onClose={onClose}
            supplier={supplier}
            updateSupplier={updateSupplier}
            isChecked={isChecked}
            isEdit={isEdit}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
