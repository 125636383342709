import React from 'react';
export const PayPalIcon = (props) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    {...props}
  >
    <g>
      <path
        d="M18.9,2.8c0.5,0.2,1,0.5,1.4,0.8C20.8,4,21.1,4.5,21.4,5c0.3,0.5,0.5,1.1,0.6,1.8c0.1,0.7,0,1.4-0.1,2.3
		c-0.2,1-0.6,1.9-1.1,2.7c-0.5,0.8-1.1,1.5-1.8,2c-0.7,0.6-1.5,1-2.4,1.3c-0.9,0.3-1.8,0.4-2.8,0.4h-4L8.4,22H2.8l0.5-2.4H0L4.3,0
		h9.7c0.7,0,1.4,0.1,2.1,0.4c0.7,0.3,1.3,0.7,1.9,1.2c0.5,0.5,0.9,1.2,1.2,2.1c0.2,0.8,0.2,1.8,0,3c-0.2,1-0.6,1.9-1.1,2.7
		c-0.5,0.8-1.1,1.5-1.8,2c-0.7,0.6-1.5,1-2.4,1.3c-0.9,0.3-1.8,0.4-2.8,0.4H7l-1.4,6.5h-1l-0.2,1h2.8l1.4-6.5h5.2
		c0.4,0,0.8,0,1.2-0.1c0.4-0.1,0.8-0.2,1.1-0.3c0.4-0.1,0.7-0.3,1-0.4c0.3-0.2,0.6-0.4,0.9-0.6c0.1,0,0.1-0.1,0.2-0.1
		c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1-0.1,0.2-0.2c0.1,0,0.1-0.1,0.2-0.2c0.3-0.3,0.5-0.6,0.8-0.9c0.2-0.3,0.4-0.6,0.6-1
		c0.2-0.4,0.3-0.7,0.5-1.1c0.1-0.4,0.2-0.8,0.3-1.2c0.1-0.4,0.1-0.8,0.1-1.2c0-0.4,0-0.7,0-1.1c-0.1-0.3-0.1-0.7-0.3-0.9
		c-0.1-0.3-0.3-0.6-0.5-0.8c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.2-0.2-0.4-0.3C19.2,3,19.1,2.9,18.9,2.8z
		 M9.1,4L7.9,9.4h2.7c0.4,0,0.7-0.1,1.1-0.2c0.3-0.1,0.7-0.3,1-0.6c0.3-0.2,0.5-0.5,0.8-0.9c0.2-0.3,0.4-0.7,0.5-1
		c0.1-0.4,0.1-0.7,0.1-1c-0.1-0.3-0.2-0.6-0.4-0.9c-0.2-0.2-0.4-0.4-0.7-0.6C12.5,4.1,12.2,4,11.8,4H9.1z"
      />
    </g>
  </svg>
);
