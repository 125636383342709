import React, { useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import {
  Heading,
  Container,
  Box,
  Link,
  useToast,
  Text,
  HStack,
  Button,
} from '@chakra-ui/react';
import { CheckIcon, ChevronLeftIcon } from '@chakra-ui/icons';
import CreateCampaignCodeButton from '../components/campaigns/CreateCampaignCodeButton';
import { HttpContext } from '../context/HttpContext';
import CampaignDetailsContent from '../components/campaigns/CampaignDetailsContent';
import EditCampaignButton from '../components/campaigns/EditButton';
import { AuthContext } from '../context/AuthContext';
import { getMessage } from '../util';
import NotableInfo from '../common/UIcomponents/NotableInfo';
import { BudgetIcon } from '../assets/iconsComponents/budgetIcon';
import { POIcon } from '../assets/iconsComponents/poIcon';
import { CurrencyContext } from '../context/currency/CurrencyContext';
import RequestChangesCampaignButton from '../components/campaigns/RequestChangesCampaignButton';
import RejectCampaignButton from '../components/campaigns/CampaignRejectButton';
import { SINGLE_CAMPAIGN_PAGE } from './campaign/SingleCampaignPage';

export default function CampaignDetails() {
  const { id } = useParams();
  const toast = useToast();
  const history = useHistory();
  const [campaignState, setCampaignState] = useState({
    campaign: null,
    campaignCode: null,
    loading: true,
    error: null,
    meta: null,
  });
  const [state, setState] = useState({
    order: null,
    updatedOrder: null,
    orderNumber: null,
    activityLog: null,
    loading: true,
    error: null,
    pending: false,
    suppliers: null,
    refreshActivity: 0,
    refreshCampaign: 0,
  });
  const [updatedCampaignCode, setUpdatedCampaignCode] = useState(null);
  const [addPO, setAddPO] = useState(0);
  const { authAxios } = useContext(HttpContext);
  const auth = useContext(AuthContext);
  // const user = auth.getUser();
  const { formatCurrency } = useContext(CurrencyContext);

  function updateCampaignCode(campaignCode) {
    setUpdatedCampaignCode(campaignCode);
  }

  function updateCampaign({ campaign, meta }) {
    setCampaignState({
      ...campaignState,
      campaign,
      meta,
    });
  }

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await authAxios.get(`/campaigns/${id}`);
        const campaign = data.data;
        const { meta } = data;
        setCampaignState({
          ...campaignState,
          campaign,
          loading: false,
          meta,
        });
      } catch (onError) {
        const message = getMessage(onError);
        if (message) {
          toast({
            position: 'top',
            description: message,
            status: 'error',
            duration: 4000,
            isClosable: true,
          });
          setTimeout(function () {
            history.push('/campaigns');
          }, 3000);
        }
      }
    };

    getData();
  }, [authAxios, id, updatedCampaignCode, addPO, state.refreshCampaign]);

  function updatePOList() {
    setAddPO(addPO + 1);
  }

  async function handleApproving(e) {
    try {
      e.preventDefault();
      setState({
        ...state,
        pending: true,
      });

      let url = `/campaigns/${id}/approve-by-pod-leader`;
      if (auth.isFinance()) {
        if (sCode) {
          url = `/campaigns/${id}/approve-again`;
        } else {
          url = `/campaigns/${id}/approve`;
        }
      }

      const { data } = await authAxios.post(url);

      setState({
        ...state,
        orderNumber: data.orderNumber,
        loading: false,
        pending: false,
      });
      setCampaignState({
        ...campaignState,
        campaign: data,
        loading: false,
      });

      toast({
        position: 'top',
        description: `You have successfully approved the campaign.`,
        status: 'success',
        duration: 6000,
        isClosable: true,
      });
    } catch (onError) {
      console.log(onError);
      const message = getMessage(onError);
      if (message) {
        toast({
          position: 'top',
          description: message,
          status: 'error',
          duration: 6000,
          isClosable: true,
        });
      }
      setState({
        ...state,
        error: 'Something went wrong',
        loading: false,
        pending: false,
      });
    }
  }

  function updateActivityLog(activityLog) {
    setState({
      ...state,
      activityLog,
    });
    toast({
      position: 'top',
      description: `You have successfully sent your comment. You will be notified when the ${
        auth.isPodLeader() ? 'campaign' : ''
      } team responds.`,
      status: 'success',
      duration: 6000,
      isClosable: true,
    });
  }

  const { campaign, meta } = campaignState;
  const sCode = (campaign && campaign.campaignCode) || updatedCampaignCode;

  const userType = auth.isPodLeader() ? 'podLeader' : auth.isFinance() ? 'finance' : null;

  function refreshCampaignAndActivityHandler() {
    const newState = {
      ...state,
      refreshCampaign: state.refreshCampaign + 1,
      refreshActivity: state.refreshActivity + 1,
    };

    setState(newState);
  }

  let canReviewRequestedChanges =
    (campaign?.status !== 'initial' &&
      campaign?.approvalStatus === 'pending_to_pod_leader' &&
      auth.isPodLeader() &&
      auth.getUser()?.id === campaign?.podLeader?.id) ||
    (sCode && campaign?.approvalStatus === 'pending_to_finance' && auth.isFinance());
  return (
    <Container w="100%" maxWidth="100%" pt={4} pr={10} pl={10} pb={14}>
      {campaign && (
        <>
          <Link color="brand.500" as={RouterLink} to="/campaigns" fontSize="14px">
            <ChevronLeftIcon /> Campaigns
          </Link>
          {(auth.isPodLeader() || auth.isFinance) && (
            <>
              {/*{auth.isFinance() && (campaign && campaign?.approvalStatus === 'pending_to_finance') && (*/}
              {/*  <Box as="p" color="#333" mb="20px" mt="20px">*/}
              {/*    <HStack>*/}
              {/*      <Button*/}
              {/*        type="submit"*/}
              {/*        colorScheme="brand"*/}
              {/*        size="sm"*/}
              {/*        pt="10px"*/}
              {/*        pb="10px"*/}
              {/*        fontSize="14px"*/}
              {/*        fontWeight="500"*/}
              {/*        _hover={{ bg: '#000' }}*/}
              {/*        onClick={handleApproving}*/}
              {/*        disabled={state.pending}*/}
              {/*      >*/}
              {/*        <CheckIcon mr="10px" />*/}
              {/*        Approve*/}
              {/*      </Button>*/}
              {/*      <span>or</span>*/}
              {/*      <RequestChangesCampaignButton*/}
              {/*        updateActivityLog={updateActivityLog}*/}
              {/*        userType={*/}
              {/*          auth.isPodLeader()*/}
              {/*            ? 'podLeader'*/}
              {/*            : auth.isFinance()*/}
              {/*            ? 'finance'*/}
              {/*            : null*/}
              {/*        }*/}
              {/*      />*/}
              {/*      /!*{order?.approvedAt === null && <RejectPOButton order={order} />}*!/*/}
              {/*      <RejectCampaignButton campaign={campaign} />*/}
              {/*    </HStack>*/}
              {/*  </Box>*/}
              {/*)}*/}

              {campaign?.status === 'initial' &&
                campaign?.approvalStatus === 'pending_to_pod_leader' &&
                auth.isPodLeader() &&
                auth.getUser()?.id === campaign?.podLeader?.id && (
                  <>
                    <Box as="p" color="#333" mb="20px" mt="30px">
                      The campaign is pending your approval.
                    </Box>
                    <HStack>
                      <Button
                        type="submit"
                        colorScheme="brand"
                        size="sm"
                        pt="10px"
                        pb="10px"
                        fontSize="14px"
                        fontWeight="500"
                        _hover={{ bg: '#000' }}
                        onClick={handleApproving}
                        disabled={state.pending}
                      >
                        <CheckIcon mr="10px" />
                        Approve
                      </Button>
                      <span>or</span>
                      <RequestChangesCampaignButton
                        updateActivityLog={updateActivityLog}
                        userType={userType}
                        refreshCampaignAndActivity={refreshCampaignAndActivityHandler}
                        parent={SINGLE_CAMPAIGN_PAGE}
                      />
                      <RejectCampaignButton
                        campaign={campaign}
                        userType={userType}
                        refreshCampaignAndActivity={refreshCampaignAndActivityHandler}
                      />
                    </HStack>
                  </>
                )}
            </>
          )}
          {canReviewRequestedChanges && (
            <>
              <Box as="p" color="#333" mb="20px" mt="30px">
                The campaign is pending your approval. You can review the changes in
                detail by clicking on the link below.
                <Box as="p" color="#333" mb="20px" mt="30px">
                  <Link
                    color="brand.500"
                    as={RouterLink}
                    to={`/campaigns/${id}/campaigns-request-changes`}
                    fontSize="18px"
                  >
                    Review requested changes
                  </Link>
                </Box>
              </Box>
              {/*<HStack>*/}
              {/*  (*/}
              {/*  <Button*/}
              {/*    type="submit"*/}
              {/*    colorScheme="brand"*/}
              {/*    size="sm"*/}
              {/*    pt="10px"*/}
              {/*    pb="10px"*/}
              {/*    fontSize="14px"*/}
              {/*    fontWeight="500"*/}
              {/*    _hover={{ bg: '#000' }}*/}
              {/*    onClick={handleApproving}*/}
              {/*    disabled={state.pending}*/}
              {/*  >*/}
              {/*    <CheckIcon mr="10px" />*/}
              {/*    Approve*/}
              {/*  </Button>*/}
              {/*  )*/}
              {/*  <span>or</span>*/}
              {/*  <RequestChangesCampaignButton*/}
              {/*    updateActivityLog={updateActivityLog}*/}
              {/*    userType={userType}*/}
              {/*    refreshCampaignAndActivity={refreshCampaignAndActivityHandler}*/}
              {/*    parent={SINGLE_CAMPAIGN_PAGE}*/}
              {/*  />*/}
              {/*  <RejectCampaignButton*/}
              {/*    campaign={campaign}*/}
              {/*    userType={userType}*/}
              {/*    refreshCampaignAndActivity={refreshCampaignAndActivityHandler}*/}
              {/*  />*/}
              {/*</HStack>*/}
            </>
          )}
          {auth.isFinance() && campaign?.approvalStatus === 'pending_to_finance' ? (
            <>
              <Box as="p" color="#333" mb="20px" mt="30px">
                {!sCode
                  ? 'The campaign team has requested a new campaign code and it is pending your approval.'
                  : 'Or you can start an approval action right away.'}
                {/*: 'The campaign team is pending your approval.'}*/}
              </Box>
              <HStack>
                {!sCode &&
                auth.isFinance() &&
                campaign?.approvalStatus === 'pending_to_finance' ? (
                  <>
                    {/*<Box as="p" color="#333" mb="20px" mt="30px">*/}
                    {/*  The campaign team has requested a new campaign code and it is pending your*/}
                    {/*  approval.*/}
                    {/*</Box>*/}
                    <CreateCampaignCodeButton
                      updateCampaignCode={updateCampaignCode}
                      text="Approve"
                      icon="check"
                    />
                    {/*<Box h="1px" width="100%" bg="#e8e9e9" mt="30px" mb="17px" />*/}
                  </>
                ) : sCode &&
                  !campaign.contractFile &&
                  auth.isFinance() &&
                  campaign?.approvalStatus === 'pending_to_finance' ? (
                  <>
                    {/*<Box as="p" color="#333" mb="20px" mt="30px">*/}
                    {/*  The campaign team has requested a new campaign code and it is pending your*/}
                    {/*  approval.*/}
                    {/*</Box>*/}
                    <CreateCampaignCodeButton
                      updateCampaignCode={updateCampaignCode}
                      text="Approve"
                      icon="check"
                      code={sCode}
                    />
                    {/*<Box h="1px" width="100%" bg="#e8e9e9" mt="30px" mb="17px" />*/}
                  </>
                ) : (
                  <Button
                    type="submit"
                    colorScheme="brand"
                    size="sm"
                    pt="10px"
                    pb="10px"
                    fontSize="14px"
                    fontWeight="500"
                    _hover={{ bg: '#000' }}
                    onClick={handleApproving}
                    disabled={state.pending}
                  >
                    <CheckIcon mr="10px" />
                    Approve
                  </Button>
                )}
                <span>or</span>
                <RequestChangesCampaignButton
                  updateActivityLog={updateActivityLog}
                  userType={userType}
                  refreshCampaignAndActivity={refreshCampaignAndActivityHandler}
                  parent={SINGLE_CAMPAIGN_PAGE}
                />
                <RejectCampaignButton
                  campaign={campaign}
                  userType={userType}
                  refreshCampaignAndActivity={refreshCampaignAndActivityHandler}
                />
              </HStack>
              <Box h="1px" width="100%" bg="#e8e9e9" mt="30px" mb="17px" />
            </>
          ) : null}
          <Box>
            <Heading
              as="h2"
              size="lg"
              textAlign="left"
              fontWeight="700"
              pt={4}
              mb={1}
              d="inline-block"
            >
              {campaign.campaignName}
            </Heading>
            {auth.isCampaignManager() && campaign?.canEditAsCampaignManager && (
              <EditCampaignButton campaign={campaign} updateCampaign={updateCampaign} />
            )}
            <Text mb={5}>
              {moment(campaign.startDate).format('DD MMM y')} -{' '}
              {moment(campaign.endDate).format('DD MMM y')}
            </Text>
            <NotableInfo
              icon={<BudgetIcon style={{ height: '26px', fill: 'white' }} />}
              textTop={`Remaining budget (${campaign?.currency?.code})`}
              textBottom={formatCurrency(meta.remainingBudget, campaign.currency.code)}
            />
            <NotableInfo
              icon={<POIcon style={{ height: '26px', fill: 'white' }} />}
              textTop={`TOTAL POs ISSUED (${campaign.currency.code})`}
              textBottom={formatCurrency(meta.totalPoIssued, campaign.currency.code)}
              normalCase
            />
          </Box>
        </>
      )}
      {campaign && (
        <CampaignDetailsContent
          campaign={campaign}
          updatedCamapignCode={sCode}
          isCampaignManager={auth.isCampaignManager()}
          updatePOList={updatePOList}
          refreshActivity={state.refreshActivity}
          refreshCampaign={state.refreshCampaign}
        />
      )}
    </Container>
  );
}
