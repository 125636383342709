import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Box,
  useToast,
} from '@chakra-ui/react';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { HttpContext } from '../../context/HttpContext';
import { CurrencyContext } from '../../context/currency/CurrencyContext';
import { getBase64Promise, getErrors, getMessage } from '../../util';

import SelectCurrency from '../../common/UIcomponents/SelectCurrency';
import DatePicker from './CreateCampaignDatePicker';
import { CampaignContext } from '../../context/campaign/FilterCampaigns';
import moment from 'moment';
import UploadFiles from './UploadCampaignFile';
import SelectPodLeader from '../../common/UIcomponents/SelectPodLeader';
import { PodLeadersContext } from '../../context/podLeader/PodleadersContext';

const CampaignForm = ({ onClose, addCampaign }) => {
  // const [currencyId, setCurrencyId] = React.useState(getFirstCurrency()?.id);
  const { getFirstCurrencyId } = useContext(CurrencyContext);
  const { getFirstId: getFirstPodLeaderId } = useContext(PodLeadersContext);
  const [currencyId, setCurrencyId] = React.useState(getFirstCurrencyId);
  const [podLeaderId, setPodLeaderId] = React.useState(getFirstPodLeaderId || 0);
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [done, setDone] = React.useState(false);
  const [uploadedFile, setUploadedFile] = useState(undefined);
  const validationSchema = Yup.object().shape({
    clientName: Yup.string().required('This is mandatory field'),
    campaignName: Yup.string()
      .max(60, 'Please enter 60 characters or less')
      .required('This is mandatory field'),
    budget: Yup.number()
      .typeError(
        'Only numerical input is allowed, if decimal number is needed please use . to separate numbers (e.g. 3.14).'
      )
      .required('This is mandatory field')
      .min(1, 'Budget must be at least 1'),
    // .max(5000, 'Very costly!'),

    // BE: This value should be 1 or more.

    // budget: Yup.number().test(
    //   "maxDigitsAfterDecimal",
    //   "Budget field it must be positive and must have two digits after decimal or less",
    //   (number) => Number.isInteger(number * 10 ** 2) && number > 0
    // ),
    // budget: Yup.number().test('is-decimal', 'invalid decimal', value => (value + "").match(/^\d*\.{1}\d*$/),),
    // startDate: Yup.date().default(function () {return new Date();}),
    // endDate: Yup.string().default(function () {return new Date();})
  });

  // console.log({ podLeaderId });

  const { setFrom } = useContext(CampaignContext);
  const { setTo } = useContext(CampaignContext);

  const { register, handleSubmit, formState, setError, reset } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });
  const { errors, isSubmitting, isDirty, isValid } = formState;
  const toast = useToast();
  const { authAxios } = useContext(HttpContext);

  async function getBase64(file) {
    return await getBase64Promise(file);
  }

  const onSubmit = async (values) => {
    try {
      let data = {
        clientName: values.clientName,
        campaignName: values.campaignName,
        budget: values.budget,
        currency: currencyId,
        startDate,
        endDate,
        file: '',
        podLeader: podLeaderId,
      };

      if (uploadedFile) {
        const base64 = await getBase64(uploadedFile);
        const file = base64.split(',')[1];
        data = {
          ...data,
          file,
        };
      }

      const res = await authAxios.post(`campaigns`, data);
      setFrom(undefined);
      setTo(undefined);
      addCampaign(res.data);
      onClose();
      reset();
      setDone(true);
    } catch (onError) {
      const errors = getErrors(onError);
      if (errors && errors.clientName && errors.clientName.errors) {
        const message = errors.clientName.errors[0];
        setError('clientName', { type: 'server', message });
      } else if (errors && errors.campaignName && errors.campaignName.errors) {
        const message = errors.campaignName.errors[0];
        setError('campaignName', { type: 'server', message });
      } else if (errors && errors.budget && errors.budget.errors) {
        const message = errors.budget.errors[0];
        setError('budget', { type: 'server', message });
      } else if (errors && errors.currency && errors.currency.errors) {
        const message = errors.currency.errors[0];
        setError('currency', { type: 'server', message });
      } else {
        const message = getMessage(onError);
        if (message) {
          toast({
            position: 'top-left',
            description: message,
            status: 'error',
            duration: 6000,
            isClosable: true,
          });
        }
      }
    }
  };

  function handleOnClose() {
    setFrom('');
    setTo('');
    onClose();
  }

  const updateCurrencyId = (id) => {
    setCurrencyId(id);
  };
  const updatePodLeaderId = (id) => {
    setPodLeaderId(id);
  };

  function updateStartDate(date) {
    setStartDate(moment(date).format('YYYY-MM-DD'));
  }

  function updateEndDate(date) {
    setEndDate(moment(date).format('YYYY-MM-DD'));
  }

  function isValidDate() {
    if (startDate && endDate) {
      if (startDate <= endDate) {
        return true;
      }
    }
    return false;
  }

  function updateUploadedFile(file) {
    setUploadedFile(file);
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={errors.clientName} mb={[4, 4, 8]}>
          <FormLabel htmlFor="emailHtmlFor">Client</FormLabel>
          <Input
            isInvalid
            name="clientName"
            id="clientName"
            placeholder="Enter client name"
            focusBorderColor="black"
            errorBorderColor="error.500"
            {...register('clientName')}
          />
          <FormErrorMessage>
            {errors.clientName?.message || errors.clientName}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.campaignName} mb={[4, 4, 8]}>
          <FormLabel htmlFor="emailHtmlFor">Campaign name</FormLabel>
          <Input
            isInvalid
            name="campaignName"
            id="campaignName"
            placeholder="Enter campaign name"
            focusBorderColor="black"
            errorBorderColor="error.500"
            {...register('campaignName')}
          />
          <FormErrorMessage>
            {errors.campaignName?.message || errors.campaignName}
          </FormErrorMessage>
        </FormControl>
        <DatePicker updateStartDate={updateStartDate} updateEndDate={updateEndDate} />
        <FormControl isInvalid={errors.currency} mb={[4, 4, 8]}>
          <FormLabel htmlFor="emailHtmlFor">Currency</FormLabel>
          <SelectCurrency updateCurrencyId={updateCurrencyId} />
          <FormErrorMessage>
            {errors.currency?.message || errors.currency}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.budget} mb={[4, 4, 8]}>
          <FormLabel htmlFor="emailHtmlFor">Budget</FormLabel>
          <Input
            isInvalid
            name="budget"
            id="budget"
            placeholder="Enter amount"
            focusBorderColor="black"
            errorBorderColor="error.500"
            {...register('budget')}
          />
          <FormErrorMessage>{errors.budget?.message || errors.budget}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.currency} mb={[4, 4, 8]}>
          <FormLabel htmlFor="emailHtmlFor">Pod Leader</FormLabel>
          <SelectPodLeader
            updatePodLeaderId={updatePodLeaderId}
            podLeaderId={podLeaderId}
          />
          <FormErrorMessage>
            {errors.currency?.message || errors.currency}
          </FormErrorMessage>
        </FormControl>

        <FormLabel htmlFor="emailHtmlFor">Attach a document (optional)</FormLabel>
        <UploadFiles updateUploadedFile={updateUploadedFile} />
        <>
          <Box d="flex" justifyContent="flex-end" mt={9} mb={9}>
            <Button
              type="submit"
              size="lg"
              colorScheme="brand"
              disabled={!isDirty || !isValid || !isValidDate() || isSubmitting}
              isLoading={isSubmitting && !done}
              textTransform="initial"
              _hover={{ bg: '#000' }}
              borderRadius="10px"
              mr="10px"
              //disabled={disableForm}
            >
              Submit
            </Button>
            <Button
              variant="ghost"
              size="lg"
              colorScheme="brand"
              textTransform="initial"
              borderRadius="10px"
              onClick={handleOnClose}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
          </Box>
        </>
      </form>
    </>
  );
};
export default CampaignForm;
