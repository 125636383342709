import React, { useContext } from 'react';
import { Box, Heading, HStack, Text } from '@chakra-ui/react';
import CampaignActivity from './CampaignActivity';
import CampaignStatusBadge from './CampaignStatusBadge';
import RequestPurchaseOrderButton from '../purchaseOrders/RequestPurchaseOrderButton';
import POList from './CampaignPurchaseOrder/POList';
import { CurrencyContext } from '../../context/currency/CurrencyContext';
import UploadedFile from '../vendors/UploadedFile';
import CampaignApprovalStatusBadge from './CampaignApprovalStatusBadge';
import ScrollToTopOnMount from '../../util/scrollToTopOnMount';

export default function CampaignDetailsContent({
  campaign,
  updatedCampaignCode,
  updatePOList,
  refreshActivity,
  isCampaignManager,
}) {
  const { formatCurrency } = useContext(CurrencyContext);
  let code = campaign.campaignCode;
  if (code === null) {
    code = updatedCampaignCode;
  }

  return (
    <>
      <ScrollToTopOnMount />
      <Box
        bg="#fff"
        borderRadius="md"
        boxShadow="0 1px 13px rgba(29, 44, 45, 0.12);"
        mt="4"
        p="10"
      >
        <HStack>
          <Box pr="3">
            <Box color="#868789" fontSize="14px">
              Campaign code
            </Box>
            {campaign.campaignCode ? (
              <Box h="24px" fontSize="20px" fontWeight="bold" lineHeight="20px">
                {campaign.campaignCode}
              </Box>
            ) : (
              <Box h="24px" fontSize="14px" color="#a9b1b4" lineHeight="20px">
                pending
              </Box>
            )}
          </Box>
          <Box h="45px" w="1px" bg="#e8e9e9" />
          <Box pr="3" pl="3">
            <Box color="#868789" fontSize="14px">
              Client
            </Box>{' '}
            <Box>{campaign.clientName} </Box>
          </Box>
          <Box h="45px" w="1px" bg="#e8e9e9" />
          <Box pr="3" pl="3">
            <Box color="#868789" fontSize="14px">
              Currency
            </Box>{' '}
            <Box>{`${campaign.currency.code} - ${campaign.currency.name}`}</Box>
          </Box>
          <Box h="45px" w="1px" bg="#e8e9e9" />
          <Box pr="3" pl="3">
            <Box color="#868789" fontSize="14px">
              Budget
            </Box>{' '}
            <Box>{formatCurrency(campaign.budget, campaign.currency.code)}</Box>
          </Box>
          <Box h="45px" w="1px" bg="#e8e9e9" />
          <Box pr="3" pl="3">
            <Box color="#868789" fontSize="14px">
              Attachment
            </Box>
            {campaign.file ? (
              <UploadedFile id={campaign.id} type="campaign" />
            ) : (
              <Box color="#a9b1b4" fontSize="14px" fontWeight="400">
                No files.
              </Box>
            )}
          </Box>
          <Box h="45px" w="1px" bg="#e8e9e9" />
          <Box pr="3" pl="3">
            <Box color="#868789" fontSize="14px">
              Contract
            </Box>
            {campaign.contractFile ? (
              <UploadedFile id={campaign.id} type="campaignContract" />
            ) : (
              <Box color="#a9b1b4" fontSize="14px" fontWeight="400">
                No files.
              </Box>
            )}
          </Box>

          {campaign?.contractFile2 && (
            <>
              <Box h="45px" w="1px" bg="#e8e9e9" />
              <Box pr="3" pl="3">
                <Box color="#868789" fontSize="14px">
                  Contract 2
                </Box>
                <UploadedFile id={campaign.id} type="campaignContract2" />
              </Box>
            </>
          )}

          {campaign?.contractFile3 && (
            <>
              <Box h="45px" w="1px" bg="#e8e9e9" />
              <Box pr="3" pl="3">
                <Box color="#868789" fontSize="14px">
                  Contract 3
                </Box>
                <UploadedFile id={campaign.id} type="campaignContract3" />
              </Box>
            </>
          )}

          {campaign?.contractFile4 && (
            <>
              <Box h="45px" w="1px" bg="#e8e9e9" />
              <Box pr="3" pl="3">
                <Box color="#868789" fontSize="14px">
                  Contract 4
                </Box>
                <UploadedFile id={campaign.id} type="campaignContract4" />
              </Box>
            </>
          )}

          {campaign?.contractFile5 && (
            <>
              <Box h="45px" w="1px" bg="#e8e9e9" />
              <Box pr="3" pl="3">
                <Box color="#868789" fontSize="14px">
                  Contract 5
                </Box>
                <UploadedFile id={campaign.id} type="campaignContract5" />
              </Box>
            </>
          )}

          <Box h="45px" w="1px" bg="#e8e9e9" />
          <Box pl="3">
            <CampaignStatusBadge status={campaign.status} />
          </Box>

          {campaign.approvalStatus && campaign.approvalStatus !== '' && (
            <>
              <Box h="45px" w="1px" bg="#e8e9e9" />
              <Box pl="3">
                <CampaignApprovalStatusBadge status={campaign.approvalStatus} />
              </Box>
            </>
          )}
        </HStack>
        <CampaignActivity
          id={campaign.id}
          campaign={campaign}
          code={code}
          refreshActivity={refreshActivity}
        />
      </Box>
      <Box>
        <Heading variant="h4" fontSize="22px" fontWeight="bold" mt="35px">
          Purchase Orders ({campaign.numberOfPurchaseOrders})
        </Heading>
        {!campaign.campaignCode && isCampaignManager && (
          <Text fontSize="14px" color="#a9b1b4" mt="18px">
            You can request a Purchase order for this campaign after the finance team
            approve the campaign.
            {/*No purchase orders yet.*/}
          </Text>
        )}
        <Box mt="25px">
          {campaign.campaignCode &&
            campaign.status === 'active' &&
            ['approved', 'initial'].includes(campaign.approvalStatus) &&
            isCampaignManager && (
              <RequestPurchaseOrderButton fromCampaign updatePOList={updatePOList} />
            )}
        </Box>
        {campaign.campaignCode && <POList pageSize={campaign.numberOfPurchaseOrders} />}
      </Box>
    </>
  );
}
