import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Box,
  useToast,
} from '@chakra-ui/react';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
// import debounce from "lodash/debounce";
import { HttpContext } from '../../context/HttpContext';
import { getErrors, getMessage } from '../../util';
import { useDebounce } from '../../custom-hooks/useDebounce';
import UploadFiles from './UploadCampaignFile';
import { getBase64Promise } from '../../util';

const CampaignCodeForm = ({ onClose, updateCampaignCode }) => {
  const { id } = useParams();
  const [done, setDone] = React.useState(false);
  const [uploadedFile, setUploadedFile] = useState(undefined);
  const [uploadedFile2, setUploadedFile2] = useState(undefined);
  const [uploadedFile3, setUploadedFile3] = useState(undefined);
  const [uploadedFile4, setUploadedFile4] = useState(undefined);
  const [uploadedFile5, setUploadedFile5] = useState(undefined);

  const validationSchema = Yup.object().shape({
    campaignCode: Yup.string()
      .max(12, 'Please enter 12 characters or less')
      .required('This is mandatory field'),
  });

  const { register, handleSubmit, formState, reset, setError, watch } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const { errors, isSubmitting, isDirty, isValid } = formState;
  const toast = useToast();
  const { authAxios } = useContext(HttpContext);
  const history = useHistory();
  const watchValue = watch('campaignCode');

  const debouncedValue = useDebounce(watchValue, 350);
  useEffect(() => {
    const getData = async () => {
      try {
        if (debouncedValue && !(errors.campaignCode?.message || errors.campaignCode)) {
          const { data } = await authAxios.get(
            `campaigns/code/check?code=${debouncedValue}`
          );
          if (data.status === false) {
            const message =
              'This code is already in use. Please choose a different code.';
            setError('campaignCode', { type: 'server', message });
          }
        }
      } catch (onError) {
        console.log(onError);
      }
    };

    getData();
  }, [debouncedValue]);
  async function getBase64(file) {
    return await getBase64Promise(file);
  }

  const onSubmit = async (values) => {
    try {
      let submittedData = values;
      if (uploadedFile) {
        const base64 = await getBase64(uploadedFile);
        const contractFile = base64.split(',')[1];
        submittedData = {
          ...submittedData,
          contractFile,
        };
      }
      if (uploadedFile2) {
        const base642 = await getBase64(uploadedFile2);
        const contractFile2 = base642.split(',')[1];
        submittedData = {
          ...submittedData,
          contractFile2,
        };
      }

      if (uploadedFile3) {
        const base643 = await getBase64(uploadedFile3);
        const contractFile3 = base643.split(',')[1];
        submittedData = {
          ...submittedData,
          contractFile3,
        };
      }

      if (uploadedFile4) {
        const base644 = await getBase64(uploadedFile4);
        const contractFile4 = base644.split(',')[1];
        submittedData = {
          ...submittedData,
          contractFile4,
        };
      }

      if (uploadedFile5) {
        const base645 = await getBase64(uploadedFile5);
        const contractFile5 = base645.split(',')[1];
        submittedData = {
          ...submittedData,
          contractFile5,
        };
      }

      const res = await authAxios.post(`campaigns/${id}/approve`, submittedData);
      onClose();
      updateCampaignCode(res.data.campaignCode);
      reset();
      setDone(true);
      history.push(`/campaigns/${id}`);
      toast({
        position: 'top',
        description: 'You have successfully created the campaign code.',
        status: 'success',
        duration: 6000,
        isClosable: true,
      });
    } catch (onError) {
      console.log(onError);
      const errors = getErrors(onError);
      if (errors && errors.campaignCode && errors.campaignCode.errors) {
        const message = errors.campaignCode.errors[0];
        setError('campaignCode', { type: 'server', message });
      }
      const message = getMessage(onError);
      if (message) {
        toast({
          position: 'top',
          description: message,
          // "This code is already in use. Please choose a different code.",
          status: 'error',
          duration: 6000,
          isClosable: true,
        });
      }
    }
  };
  function updateUploadedFile(file) {
    setUploadedFile(file);
  }
  function updateUploadedFile2(file) {
    setUploadedFile2(file);
  }

  function updateUploadedFile3(file) {
    setUploadedFile3(file);
  }
  function updateUploadedFile4(file) {
    setUploadedFile4(file);
  }
  function updateUploadedFile5(file) {
    setUploadedFile5(file);
  }
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={errors.campaignCode} mb={[4, 4, 8]}>
          <FormLabel htmlFor="emailHtmlFor">Campaign code</FormLabel>
          <Input
            isInvalid
            name="CampaignCode"
            id="campaignCode"
            placeholder="Enter campaign code"
            textTransform="uppercase"
            _placeholder={{ textTransform: 'initial' }}
            focusBorderColor="black"
            errorBorderColor="error.500"
            {...register('campaignCode')}
          />
          <FormErrorMessage>
            {errors.campaignCode?.message || errors.campaignCode}
          </FormErrorMessage>
        </FormControl>
        <FormLabel htmlFor="emailHtmlFor">Attach a documents</FormLabel>
        <Box color="#868789" fontSize="14px">
          Document 1.
        </Box>
        <UploadFiles updateUploadedFile={updateUploadedFile} />
        <Box color="#868789" fontSize="14px">
          Document 2. (optional)
        </Box>
        <UploadFiles updateUploadedFile={updateUploadedFile2} />
        <Box color="#868789" fontSize="14px">
          Document 3. (optional)
        </Box>
        <UploadFiles updateUploadedFile={updateUploadedFile3} />
        <Box color="#868789" fontSize="14px">
          Document 4. (optional)
        </Box>
        <UploadFiles updateUploadedFile={updateUploadedFile4} />
        <Box color="#868789" fontSize="14px">
          Document 5. (optional)
        </Box>
        <UploadFiles updateUploadedFile={updateUploadedFile5} />
        {/*<UploadFiles updateUploadedFile={updateUploadedFile} />*/}
        {/*<UploadFiles updateUploadedFile={updateUploadedFile} />*/}
        <>
          <Box d="flex" justifyContent="flex-end" mt={20} mb={20}>
            <Button
              type="submit"
              size="lg"
              colorScheme="brand"
              disabled={!isDirty || !isValid || isSubmitting || !uploadedFile}
              isLoading={isSubmitting && !done}
              textTransform="initial"
              _hover={{ bg: '#000' }}
              borderRadius="10px"
              mr="10px"
              //disabled={disableForm}
            >
              Approve
            </Button>
            <Button
              variant="ghost"
              size="lg"
              colorScheme="brand"
              textTransform="initial"
              borderRadius="10px"
              onClick={onClose}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
          </Box>
        </>
      </form>
    </>
  );
};
export default CampaignCodeForm;
