import React, { useContext, useEffect, useState } from 'react';
import {
  Heading,
  Container,
  useToast,
  HStack,
  Box,
  Button,
  // Spinner,
} from '@chakra-ui/react';
import RequestPurchaseOrderButton from '../components/purchaseOrders/RequestPurchaseOrderButton';
import { HttpContext } from '../context/HttpContext';
import POList from '../components/purchaseOrders/POList';
import Search from '../common/UIcomponents/SearchList';
import { usePaginator } from 'chakra-paginator';
import { AuthContext } from '../context/AuthContext';
import FilterOrders from '../components/purchaseOrders/POFilters/FilterPODropdown';
import SageRestartSync from '../components/vendors/SageRestartSync';
import { DownloadIcon } from '../assets/iconsComponents/downloadIcon';
import {INITIAL_PAGE_SIZE} from "../util/consts"

export default function PortalPurchaseOrders() {
  const clearedOrderBy = {
    supplierCode: null,
    campaignCode: null,
    totalGross: null,
    totalNet: null,
    totalVat: null,
    number: null,
    status: null,
    comment: null,
    invoiceDate: null,
    invoiceDueDate: null,
    invoicePaymentDate: null,
  };
  const initialOrderBy = {
    supplierCode: null,
    campaignCode: null,
    totalGross: null,
    totalNet: null,
    totalVat: null,
    number: null,
    status: null,
    invoiceDate: null,
    invoiceDueDate: null,
    invoicePaymentDate: null,
  };
  const [poState, setPoState] = useState({
    purchaseOrders: [],
    isLoaded: false,
    total: 0,
    // orderBy: initialOrderBy
  });

  const [order, setOrder] = useState(initialOrderBy);
  const [term, setTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [filterPodLeader, setFilterPodLeader] = useState('');
  const [filterCurrency, setFilterCurrency] = useState('');
  const [filterStartBetween, setFilterStartBetween] = useState('');
  const [filterEndBetween, setFilterEndBetween] = useState('');
  const [filterGrossAmountBetween, setFilterGrossAmountBetween] = useState('');

  const { authAxios } = useContext(HttpContext);
  const auth = useContext(AuthContext);
  const toast = useToast();

  const {
    pagesQuantity,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    offset, // you may not need this most of the times, but it's returned for you anyway
  } = usePaginator({
    total: poState.total,
    initialState: {
      pageSize: INITIAL_PAGE_SIZE,
      currentPage: 1,
      isDisabled: false,
    },
  });

  function getSortingQuery(order) {
    if (order.supplierCode) {
      return `&sort=supplierCode&direction=${order.supplierCode}`;
    }
    if (order.campaignCode) {
      return `&sort=campaignCode&direction=${order.campaignCode}`;
    }
    if (order.totalGross) {
      return `&sort=totalGross&direction=${order.totalGross}`;
    }
    if (order.totalNet) {
      return `&sort=totalNet&direction=${order.totalNet}`;
    }
    if (order.totalVat) {
      return `&sort=totalVat&direction=${order.totalVat}`;
    }
    if (order.number) {
      return `&sort=number&direction=${order.number}`;
    }
    if (order.status) {
      return `&sort=status&direction=${order.status}`;
    }
    if (order.podLeader) {
      return `&sort=podLeader&direction=${order.podLeader}`;
    }
    if (order.comment) {
      return `&sort=comment&direction=${order.comment}`;
    }
    if (order.invoiceDate) {
      return `&sort=invoiceDate&direction=${order.invoiceDate}`;
    }
    if (order.invoiceDueDate) {
      return `&sort=invoiceDueDate&direction=${order.invoiceDueDate}`;
    }
    if (order.invoicePaymentDate) {
      return `&sort=invoicePaymentDate&direction=${order.invoicePaymentDate}`;
    }

    return '';
  }

  function getSearchQuery(term) {
    const trimmed = term.trim();
    if (trimmed.length > 0) {
      return `&search=${encodeURIComponent(term)}`;
    }
    return '';
  }

  useEffect(() => {
    let ignore = false;
    const getPortalData = async () => {
      try {
        const sort = getSortingQuery(order);
        const search = getSearchQuery(term);
        const { data } = await authAxios.get(
          `/purchase-orders?limit=${pageSize}&offset=${offset}${sort}${search}${filterStartBetween}${filterEndBetween}${filterStatus}${filterCurrency}${filterGrossAmountBetween}${filterPodLeader}`
        );
        const purchaseOrders = (data && data.data) || [];
        const total = (data && data.meta && data.meta.total) || 0;
        if (!ignore) {
          setPoState({
            purchaseOrders,
            total,
            isLoaded: true,
          });
        }
      } catch (onError) {
        console.log(onError);
        if (!ignore) {
          setPoState({
            ...poState,
            isLoaded: true,
          });
        }
      }
    };

    getPortalData();

    return () => {
      ignore = true;
    };
  }, [
    authAxios,
    currentPage,
    pageSize,
    offset,
    order,
    term,
    filterStatus,
    filterCurrency,
    filterStartBetween,
    filterEndBetween,
    filterGrossAmountBetween,
    filterPodLeader,
  ]);

  // TODO refactor to an avaliable handleDownload function
  const handleCsvDownload = async () => {
    try {
      const sort = getSortingQuery(order);
      const search = getSearchQuery(term);
      const csvExport = `&export=true`;
      const response = await authAxios.get(
        `purchase-orders?limit=${pageSize}&offset=${offset}${sort}${search}${filterStartBetween}${filterEndBetween}${filterStatus}${filterCurrency}${filterGrossAmountBetween}${csvExport}`,
        {
          responseType: 'blob',
        }
      );
      const blob = new Blob([response.data], { type: 'text/csv; charset=UTF-8' });
      const url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.href = url;
      a.download = 'purchase-orders.csv';
      a.click();
    } catch (onError) {
      console.log(onError);
    }
  };

  function addPurchaseOrders(data) {
    const old = poState.purchaseOrders;
    setCurrentPage(1);
    setPoState({
      ...poState,
      purchaseOrders: [data, ...old],
    });
  }

  function updateSupplier(supplier) {
    const newSuppliers = poState.purchaseOrders;
    let recordIndex = newSuppliers.findIndex((x) => x.id === supplier.id);
    newSuppliers[recordIndex] = { ...supplier };

    setPoState({
      ...poState,
      purchaseOrders: newSuppliers,
    });

    toast({
      position: 'top',
      description: 'You have successfully edit a purchase order.',
      status: 'success',
      duration: 6000,
      isClosable: true,
    });
  }

  function updateOrderBy(key, value) {
    setOrder({ ...clearedOrderBy, [key]: value });
    setCurrentPage(1);
  }

  function updateFilterStatus(status) {
    setFilterStatus(status);
    setCurrentPage(1);
  }
  function updateFilterCurrency(currency) {
    setFilterCurrency(currency);
    setCurrentPage(1);
  }

  function updateFilterStartBetween(between) {
    setFilterStartBetween(between);
    setCurrentPage(1);
  }
  function updateFilterEndBetween(between) {
    setFilterEndBetween(between);
    setCurrentPage(1);
  }
  function updateGrossAmountBetween(between) {
    setFilterGrossAmountBetween(between);
    setCurrentPage(1);
  }

  function clearAllFilters() {
    setFilterStatus('');
    setFilterPodLeader('');
    setFilterCurrency('');
    setFilterStartBetween('');
    setFilterEndBetween('');
    setFilterGrossAmountBetween('');
    setCurrentPage(1);
  }

  const handlePageChange = (nextPage) => {
    setCurrentPage(nextPage);
  };

  const handlePageSizeChange = (event) => {
    const pageSize = Number(event.target.value);
    setPageSize(pageSize);
    setCurrentPage(1);
  };

  function updateTerm(term) {
    setCurrentPage(1);
    setTerm(term);
  }

  function updateFilterPodLeader(podLeader) {
    if (podLeader === '&podLeader=0') {
      setFilterPodLeader('');
    } else {
      setFilterPodLeader(podLeader);
    }
    setCurrentPage(1);
  }

  return (
    <Container w="100%" maxWidth="100%" pt={4} pr={10} pl={10} pb={14}>
      {auth.isFinance() && auth.getAccessToken() && <SageRestartSync />}
      <Heading as="h2" size="lg" textAlign="left" fontWeight="700" pt={4} mb={[4, 4, 5]}>
        Purchase Orders
      </Heading>
      <HStack justifyContent="space-between">
        <Box>
          {auth.isCampaignManager() && (
            <RequestPurchaseOrderButton addPurchaseOrders={addPurchaseOrders} />
          )}
        </Box>
        <Box
          d="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          gap="1rem"
        >
          {auth.isFinanceManager() && (
            <Button
              leftIcon={<DownloadIcon width="17px" color="#fff" height="19px" />}
              colorScheme="brand"
              borderRadius="8px"
              fontSize="14px"
              fontWeight="500"
              pt="18px"
              pb="18px"
              _hover={poState?.purchaseOrders?.length === 0 ? null : { bg: '#000' }}
              _expanded={{ bg: 'black', color: 'white' }}
              _focus={{ boxShadow: 'none' }}
              onClick={handleCsvDownload}
              disabled={poState?.purchaseOrders?.length === 0}
            >
              Download (.csv)
            </Button>
          )}
          <Search updateTerm={updateTerm} />
          <FilterOrders
            clearAllFilters={clearAllFilters}
            updateFilterStatus={updateFilterStatus}
            updateFilterCurrency={updateFilterCurrency}
            updateFilterStartBetween={updateFilterStartBetween}
            updateFilterEndBetween={updateFilterEndBetween}
            updateGrossAmountBetween={updateGrossAmountBetween}
            updateFilterPodLeader={updateFilterPodLeader}
          />
        </Box>
      </HStack>
      <POList
        purchaseOrders={poState.purchaseOrders}
        handlePageSizeChange={handlePageSizeChange}
        handlePageChange={handlePageChange}
        currentPage={currentPage}
        pagesQuantity={pagesQuantity}
        updateSupplier={updateSupplier}
        isCampaign={auth.isCampaignManager()}
        isSupplier={auth.isSupplier()}
        isFinance={auth.isFinanceManager()}
        orderBy={order}
        updateOrderBy={updateOrderBy}
        updateFilterStartBetween={updateFilterStartBetween}
        updateFilterEndBetween={updateFilterEndBetween}
        loading={!poState.isLoaded}
      />
    </Container>
  );
}
